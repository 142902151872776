<template>
  <div class="page">
    <div class="top">
      <div class="top-wrapper">
        <img src="./assets/logo.png" class="logo">
        <button class="link-button" @click="toWebankPage">进入微众银行官网<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAGFBMVEUAAAD///////////////////////////8jfp1fAAAAB3RSTlMA9fI6Qj55plvyQQAAAD1JREFUKM9jIBekovHZCgNQBVjVRdGUBBU6oAowq4ugKTEaPEpYxJVRBRyLDOitgBVdQSAhBYhoQEQUJQAARmwOVB4BdIsAAAAASUVORK5CYII=" class="badge" /></button>
        <div class="cube">
          <img src="./assets/cube-pc.png" class="cube-pc">
          <img src="./assets/cube.png" class="cube-small">
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="./assets/logo-eng.png" class="logo">
      <div class="content">
        <div class="left">
          <div class="text-container">
            <span class="location-label">银行经营场所：</span>深圳市南山区沙河西路1819号深圳湾科技生态园7栋A座
          </div>
          <div class="text-container">
            服务与投诉热线：<a href="tel:95384">95384</a>
          </div>
        </div>
        <div class="right">
          <div class="text-container">
            Copyright © 2014-2022 WeBank / All Rights Reserved / 微众银行版权所有 /
            <span class="link-text" @click="clickCopyright">粤ICP备14074715号</span>
          </div>
          <div class="text-container">
            <span>本网站支持IPv6 / </span>
            <img class="badge" src="./assets/publicSecurity.png">
            <span class="link-text" @click="clickPublicSecurity"> 粤公网安备44030502005181号</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const toWebankPage = () => {
  location.href = 'https://www.webank.com'
};
const clickCopyright = () => {
  location.href = 'https://beian.miit.gov.cn'
};
const clickPublicSecurity = () => {
  location.href = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502005181'
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;

  // 各种尺寸下都一致的
  .top {
    display: flex;
    align-items: center;
    width: 100%;
    background-size:100% 100%;
    background-position: center top;
    background-repeat: no-repeat;
    overflow: hidden;
    text-align: center;
    .top-wrapper{
      margin: 0 auto;
      padding: 64px 0;
    }
    .logo {
      width: 320px;
      display: block;
      margin-bottom: 48px;
    }
    .link-button {
      display: flex;
      padding: 0 16px;
      margin: 0 auto 68px;
      background: #2C5BD8;
      border-color: #2C5BD8;
      color: #FFFFFF;
      line-height: 48px;
      border: 0;
      outline: 0;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      .badge{
        width: 16px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .bottom {
    padding: 64px 16px;
    width: 1200px;
    margin: 0 auto;
    .logo{
      width: 122px;
      display: block;
      margin-bottom: 16px;
    }
    .content {
      display: flex;
      .text-container{
        padding-bottom: 8px;
        display: flex;
        align-items: center;
      }
      .badge {
        width: 16px;
        height: 16px;
      }
    }
  }
}

// 宽度大于500的设备
@media screen and (min-width: 900px) {
  .page {
    display: grid;
    grid-template-rows: 1fr 300px;
  }
  .top {
    min-height: 720px;
    background-image: url('./assets/background-pc.jpg');
    .cube {
      .cube-pc {
        width: 200px;
      }
      .cube-small {
        display: none;
      }
    }
  }
  .bottom {
    .content {
      justify-content: space-between;
    }
  }
}


// 宽度小于500px的屏幕，移动设备
@media screen and (max-width: 900px) {
  .page{
    .top {
      background-image: url('./assets/background.jpg');
      height: 620px;
      align-items: unset;
      .logo{
        width: 280px;
        margin-bottom: 36px;
      }
      .link-button{
        margin-bottom: 54px;
      }
      .cube {
        height: 30vh;
        max-height: 146px;
        .cube-pc {
          display: none;
        }
        .cube-small {
          width: 180px;
        }
      }
    }
    .bottom {
      width: 100%;
      padding: 54px 16px;
      .logo {
        display: none !important;
      }
      min-width: 320px;
      font-weight: 400;
      color: #999999;
      .content {
        display: block;
        text-align: center;
        .right {
          position: relative;
          padding-top: 8px;
          &::after{
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #DADFE6;
            position: absolute;
            top: 0;
            left: 0;
            transform: scaleY(0.5);
          }
        }
        .text-container{
          display: block;
          .location-label{
            display: none;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
*{
  box-sizing: border-box;
}
body,html,#app {
  height: 100%;
  min-width: 320px;
  // 需要限制最小宽度
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #999;
}
a {
  text-decoration: none;
  color: #999;
}
</style>